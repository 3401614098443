<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <b-img
        :src="appLogoImage"
        alt="logo"
        center
        width="200px"
        class="mb-5"
      />
      <div class="w-100 text-center">
        <div v-if="!signinError">
          <h2 class="mb-1">
            Waiting... We sign you in.
          </h2>
        </div>

        <div v-else>
          <h2 class="mb-1">
            {{ signinError }}
          </h2>
          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{path:'/'}"
          >
            Back to home
          </b-button>
        </div>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
    BButton,
  },
  data() {
    return {
      signinError: null,
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app

    return {
      appLogoImage,
    }
  },
  mounted() {
    if (this.$route.query.error) {
      if (this.$route.query.error === 'access_denied') {
        this.signinError = 'Login has been canceled.'
      } else {
        this.signinError = `Login failed with error ${this.$route.query.error}.`
      }
    } else {
      this.$store.dispatch('auth/signinRedirectCallback')
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
